import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import useBoundStore from '~/lib/store'
import QueryPublicSettingJobFields from '../graphql/query-public-setting-jobs-fields'

const usePublicSettingJobFields = () => {
  const router = useRouter()
  const { user } = useBoundStore()
  const {
    isLoading,
    trigger: fetchTenantSettingsJobFieldsData,
    data: tenantSettingsJobFieldsData
  } = useQueryGraphQL({
    query: QueryPublicSettingJobFields,
    variables: {
      tenantSlug: router.query?.['id']
        ? String(router.query?.['id'])
        : user?.currentTenant?.slug || ''
    },
    shouldPause: true
  })

  useEffect(() => {
    fetchTenantSettingsJobFieldsData()
  }, [])

  const isShowSystemFieldWithCareerSite = useCallback(
    (fieldName: string) => {
      if (isLoading) return false
      if (
        !tenantSettingsJobFieldsData ||
        tenantSettingsJobFieldsData?.publicSettingJobFields?.length === 0
      )
        return true

      const jobField = tenantSettingsJobFieldsData.publicSettingJobFields.find(
        (f) => f.field === fieldName
      )
      return jobField?.visibility && jobField?.career_site_visibility
    },
    [tenantSettingsJobFieldsData, isLoading]
  )

  const isShowSystemFieldWithFilter = useCallback(
    (fieldName: string) => {
      if (isLoading) return false
      if (
        !tenantSettingsJobFieldsData ||
        tenantSettingsJobFieldsData?.publicSettingJobFields?.length === 0
      )
        return true

      const jobField = tenantSettingsJobFieldsData.publicSettingJobFields.find(
        (f) => f.field === fieldName
      )
      return (
        jobField?.visibility &&
        jobField?.career_site_visibility &&
        jobField?.filter_visibility
      )
    },
    [tenantSettingsJobFieldsData, isLoading]
  )

  return {
    tenantSettingsJobFieldsData,
    isShowSystemFieldWithCareerSite,
    isShowSystemFieldWithFilter
  }
}

export default usePublicSettingJobFields

import { formatDistanceToNowStrict } from 'date-fns'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import { IRouterWithID } from '~/core/@types/global'
import { REMOTE_STATUS } from '~/core/constants/enum'
import { Badge } from '~/core/ui/Badge'
import { TypographyH5 } from '~/core/ui/Heading'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { Tooltip } from '~/core/ui/Tooltip'
import { defaultFormatDate } from '~/core/utilities/format-date'
import { isBrowser } from '~/core/utilities/is-browser'
import { renderSalary } from '~/lib/features/apply/jobId/utilities/helper-salary'
import { DEFAULT_CURRENCY } from '~/lib/features/candidates/utilities/enum'
import { ICareer } from '~/lib/features/careers/[id]/types'

const CareerCard: FC<{
  data: ICareer
  tenantSlug?: IRouterWithID
  configHideInfo?: {
    salary?: boolean
    remoteStatus?: boolean
    employmentType?: boolean
  }
}> = ({ data, tenantSlug = '', configHideInfo }) => {
  const { t, i18n } = useTranslation()
  const { query } = useRouter()

  return (
    <div
      key={`career-${data.id}`}
      className="border-b border-b-gray-100 pb-[15px] pt-4 first:pt-0 tablet:pb-[23px] tablet:pt-6">
      <div className="mb-2 tablet:flex tablet:items-center">
        <Link
          href={`${pathConfiguration.careers.applyJob({
            locale: i18n.language,
            tenantSlug: String(tenantSlug),
            jobId: data.slug || ''
          })}${query.referred_by ? `?referred_by=${query.referred_by}` : ''}`}>
          <TypographyH5 className="hover-brand-color-text title-color-text contents font-medium text-gray-900 hover:text-primary-300 dark:text-gray-300 tablet:mr-3 tablet:inline-block">
            {data.title}
          </TypographyH5>
        </Link>

        {!configHideInfo?.remoteStatus &&
          data?.permittedFields?.remoteStatus?.value &&
          data.permittedFields.remoteStatus.value !==
            REMOTE_STATUS.NO_REMOTE && (
            <div className="ml-1 inline-flex tablet:ml-0">
              <Badge
                color="green"
                icon={
                  REMOTE_STATUS.HYDRID_REMOTE ===
                  data.permittedFields.remoteStatus.value
                    ? 'Signal'
                    : 'Wifi'
                }
                classNameText="truncate"
                radius="circular"
                size="md"
                type="iconLeading">
                {data.permittedFields?.remoteStatusDescription?.value}
              </Badge>
            </div>
          )}
      </div>
      <div className="flex items-end justify-between tablet:items-start">
        <div className="items-center tablet:flex">
          {data.jobLocations.length > 0 && (
            <>
              <div className="mb-1 flex items-center tablet:mb-0">
                <IconWrapper
                  name="MapPin"
                  className="flex-none text-gray-600"
                  size={16}
                />

                {data.jobLocations.length > 1 ? (
                  <Tooltip
                    content={
                      <>
                        {data.jobLocations.map((location) => (
                          <div key={`job-location-tooltip-${location.id}`}>
                            {location.state ? `${location.state}, ` : ''}
                            {location.country}
                          </div>
                        ))}
                      </>
                    }>
                    <span className="secondary-color-text ml-2 text-base text-gray-700 dark:text-gray-300">
                      {t('label:countLocation', {
                        countLocation: data.jobLocations.length
                      })}
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    content={`${
                      data.jobLocations[0].state
                        ? `${data.jobLocations[0].state}, `
                        : ''
                    }
                  ${data.jobLocations[0].country}`}>
                    <span className="secondary-color-text ml-2 line-clamp-1 break-all text-base text-gray-700 dark:text-gray-300">
                      {data.jobLocations[0].state
                        ? `${data.jobLocations[0].state}, `
                        : ''}
                      {data.jobLocations[0].country}
                    </span>
                  </Tooltip>
                )}
              </div>

              <If
                condition={
                  !configHideInfo?.salary &&
                  (data?.permittedFields?.salaryFrom ||
                    data?.permittedFields?.salaryTo)
                }>
                <div className="mx-4 hidden h-1 w-1 flex-none rounded bg-gray-400 tablet:block" />
              </If>
            </>
          )}
          <If
            condition={
              !configHideInfo?.salary &&
              (data?.permittedFields?.salaryFrom ||
                data?.permittedFields?.salaryTo)
            }>
            {!configHideInfo?.salary && (
              <div className="mb-1 flex flex-none items-center tablet:mb-0">
                <IconWrapper
                  name="DollarSign"
                  className="text-gray-600"
                  size={16}
                />
                <span className="secondary-color-text ml-2 text-base text-gray-700 dark:text-gray-300">
                  {renderSalary({
                    t,
                    salaryFrom: Number(
                      data?.permittedFields?.salaryFrom?.value
                    ),
                    salaryTo: Number(data?.permittedFields?.salaryTo?.value),
                    currency:
                      data?.permittedFields?.currency?.value ||
                      DEFAULT_CURRENCY,
                    typeOfSalaryDescription:
                      data?.permittedFields?.typeOfSalaryDescription?.value
                  })}
                </span>
              </div>
            )}
          </If>
          {!configHideInfo?.employmentType &&
            data?.permittedFields?.employmentTypeDescription?.value && (
              <>
                <div className="mx-4 hidden h-1 w-1 flex-none rounded bg-gray-400 tablet:block" />
                <div className="flex flex-none items-center">
                  <IconWrapper
                    name="Briefcase"
                    className="text-gray-600"
                    size={16}
                  />
                  <span className="secondary-color-text ml-2 text-base text-gray-700 dark:text-gray-300">
                    {data.permittedFields.employmentTypeDescription.value}
                  </span>
                </div>
              </>
            )}
        </div>
        {data?.createdAt && isBrowser() ? (
          <div className="flex-none">
            <Tooltip content={defaultFormatDate(new Date(data.createdAt))}>
              <span className="ml-1 text-base text-gray-600 dark:text-gray-300 tablet:ml-4">
                {formatDistanceToNowStrict(new Date(data.createdAt))}
              </span>
            </Tooltip>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CareerCard

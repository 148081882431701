import { gql } from 'urql'
import { boolean } from 'zod'

const QueryPublicSettingJobFields = gql<
  {
    publicSettingJobFields: Array<{
      field: string
      visibility: boolean
      career_site_visibility: boolean
      filter_visibility: boolean
    }>
  },
  {
    tenantSlug: string
  }
>`
  query ($tenantSlug: String!) {
    publicSettingJobFields(tenantSlug: $tenantSlug)
  }
`

export default QueryPublicSettingJobFields

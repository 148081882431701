import { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ITenantDetail } from '~/core/@types/global'
import { PUBLIC_APP_URL } from '~/core/constants/env'
import { IconButton } from '~/core/ui/IconButton'
import IconWrapper from '~/core/ui/IconWrapper'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { TenantType } from '~/lib/features/careers/[id]/types'
import { SectionType } from '~/lib/features/settings/careers/types/editor'
import useBoundStore from '~/lib/store'
import { SettingEditorFormType } from './CustomizeSettingTab'
import {
  AboutUsSectionPreviewUI,
  BenefitsSectionPreviewUI,
  FAQsSectionPreviewUI,
  FooterPreviewUI,
  GallerySectionPreviewUI,
  HeaderPreviewUI,
  HeroSectionPreviewUI,
  JobsSectionPreviewUI
} from './SectionsPreviewUI'

export const generateCustomizedColorStyleTag = (
  templateConfig?: SettingEditorFormType
) =>
  !templateConfig ? null : (
    <>
      <style jsx global>{`
        .brand-color-button {
          background-color: ${templateConfig?.brandColor}!important;
          --tw-ring-color: ${templateConfig?.brandColor}!important;
        }
        .brand-color-text {
          color: ${templateConfig?.brandColor}!important;
        }
        .brand-color-input-focus:focus {
          border-color: ${templateConfig?.brandColor}!important;
          --tw-shadow: 0px 0px 0px 1px ${templateConfig?.brandColor}!important;
        }
        .brand-color-border {
          border-color: ${templateConfig?.brandColor}!important;
        }
        .brand-color-ring {
          --tw-ring-color: ${templateConfig?.brandColor}!important;
        }
        .brand-color-select-focus {
          --tw-ring-color: ${templateConfig?.brandColor}!important;
        }
        .hover-brand-color-text:hover {
          color: ${templateConfig?.brandColor}!important;
        }
        .hover-brand-color-text:hover > svg {
          color: ${templateConfig?.brandColor}!important;
        }
        .title-color-text {
          color: ${templateConfig?.titleColor}!important;
        }
        .secondary-color-text {
          color: ${templateConfig?.secondaryColor}!important;
        }
      `}</style>
    </>
  )

const CareerPreviewEditor: FC<{
  isEditorPage?: boolean
  showSwitchLanguages?: boolean
  sections?: Array<SectionType>
  templateConfig?: SettingEditorFormType
  tenant?: TenantType | ITenantDetail
}> = ({
  isEditorPage = true,
  showSwitchLanguages,
  sections,
  templateConfig,
  tenant
}) => {
  const previewUIRef = useRef<HTMLDivElement>(null)
  const { user } = useBoundStore()
  const { t } = useTranslation()
  const careerUrl = useMemo(
    () =>
      `${PUBLIC_APP_URL}${
        user.currentTenant?.canonicalUrl ||
        user.currentTenant?.careerSiteSettings?.canonical_url
      }`,
    [user]
  )
  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(careerUrl)
  }, [careerUrl])

  useEffect(() => {
    if (templateConfig?.favicon && !isEditorPage) {
      var link: HTMLLinkElement | null =
        document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.head.appendChild(link)
      }

      link.href = templateConfig?.favicon
    }
  }, [templateConfig?.favicon, isEditorPage])

  return (
    <>
      {generateCustomizedColorStyleTag(templateConfig)}
      {isEditorPage && (
        <div className="flex min-h-[57px] flex-none items-center justify-between border-b border-b-gray-100 px-5 py-3">
          <div className="flex items-center space-x-2">
            <TypographyText className="text-sm text-gray-700 ">
              {careerUrl.replace(/(^\w+:|^)\/\//, '')}
            </TypographyText>
            <Tooltip
              position="bottom"
              classNameAsChild="flex-none"
              content={`${t('tooltip:copy_link')}`}>
              <IconButton
                iconMenus="Copy"
                size="xs"
                type="secondary"
                onClick={handleCopyToClipboard}
              />
            </Tooltip>
          </div>
          <div className="flex items-center space-x-4">
            <Tooltip
              classNameAsChild="flex-none"
              position="bottom"
              content={`${t('tooltip:help_center')}`}>
              <IconWrapper
                name="HelpCircle"
                size={16}
                className="text-gray-400"
              />
            </Tooltip>
          </div>
        </div>
      )}
      {/* Preview UI */}
      <div ref={previewUIRef} className="w-full flex-1 overflow-y-auto">
        <HeaderPreviewUI
          isEditorPage={isEditorPage}
          isLoading={!templateConfig}
          logo={
            !templateConfig
              ? undefined
              : templateConfig?.logo ||
                user?.currentTenant?.logoVariants?.thumb?.url
          }
          sectionJobId={
            (sections || []).find((section) => section.key === 'jobs')?.id
          }
        />
        <div className="grid min-h-[calc(100vh_-_137px)] gap-y-[100px]">
          {(sections || []).map((section, index) =>
            section.visibility
              ? {
                  hero: <HeroSectionPreviewUI section={section} />,
                  about_us: <AboutUsSectionPreviewUI section={section} />,
                  jobs: (
                    <JobsSectionPreviewUI
                      section={section}
                      isEditorPage={isEditorPage}
                      tenant={tenant}
                    />
                  ),
                  benefits: <BenefitsSectionPreviewUI section={section} />,
                  gallery: <GallerySectionPreviewUI section={section} />,
                  faqs: (
                    <FAQsSectionPreviewUI
                      section={section}
                      isEditorPage={isEditorPage}
                    />
                  )
                }[section.key]
              : null
          )}
        </div>
        <FooterPreviewUI
          isEditorPage={isEditorPage}
          showSwitchLanguages={showSwitchLanguages}
          companyName={tenant?.name}
          logo={
            !templateConfig
              ? undefined
              : templateConfig?.logo ||
                user?.currentTenant?.logoVariants?.thumb?.url
          }
        />
      </div>
    </>
  )
}

export default CareerPreviewEditor

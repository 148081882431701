import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect, useRef } from 'react'
import { Controller, UseFormReset } from 'react-hook-form'
import {
  IRouterWithID,
  ISelectOption,
  ITenantDetail
} from '~/core/@types/global'
import ComboboxSelect from '~/core/ui/ComboboxSelect'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper from '~/core/ui/IconWrapper'
import { Input } from '~/core/ui/Input'
import { InputLeftAddon, InputRightAddon } from '~/core/ui/InputAddon'
import { InputGroup } from '~/core/ui/InputGroup'
import { useCareer } from '~/lib/features/careers/[id]/hooks/use-career'
import schemaCareerSearch from '~/lib/features/careers/[id]/schema/career-search-schema'
import {
  CareerPageSettingType,
  ICareerForm,
  TenantType
} from '~/lib/features/careers/[id]/types'
import { ICareerSearchParam } from '../../../lib/features/careers/[id]/types'
import useDepartment from '~/lib/features/settings/departments/hooks/useDepartment'
import { DEPARTMENT_SHOW_TOP_LEVEL } from '~/lib/features/settings/careers/utilities/enum'
import useEnumsData from 'src/hooks/data/use-enums-data'

interface CareerSearchProps {
  tenantSlug?: IRouterWithID
  tenant?: TenantType | ITenantDetail
  filter: ICareerForm | ICareerSearchParam
  onFinishCallback: (data: ICareerSearchParam) => Promise<void>
  careerPageSetting?: CareerPageSettingType
  configHide?: {
    remoteStatus: boolean
    department?: boolean
    jobLevel?: boolean
  }
}

let careerSearchTimeout: NodeJS.Timeout
const CareerSearch: FC<CareerSearchProps> = ({
  tenantSlug,
  tenant,
  filter,
  onFinishCallback,
  careerPageSetting,
  configHide
}) => {
  const { t, i18n } = useTranslation()
  const jobRemoteStatusOptions = useEnumsData({
    enumType: 'JobRemoteStatus',
    locale: i18n.language
  })
  const jobLevelOptions = useEnumsData({
    enumType: 'JobJobLevel',
    locale: i18n.language
  })
  const { promiseLocationsOptions } = useCareer({ tenant })
  const {
    promisePublicDepartmentMultiLevelOptions,
    promisePublicDepartmentOptions
  } = useDepartment()
  const formResetFuncRef = useRef<UseFormReset<ICareerForm>>()
  const onSubmit = useCallback(
    (data: ICareerForm) => {
      const formatData = {
        location: data?.location,
        remoteStatusOption: data?.remoteStatusOption,
        search: data?.search,
        ...(data?.location
          ? {
              country: data?.location?.label,
              state: data?.location?.value
            }
          : {}),
        departmentIds: data.departmentIds,
        remoteStatus: data?.remoteStatusOption?.value
          ? data?.remoteStatusOption?.value
          : undefined,
        jobLevel: (data?.jobLevel as ISelectOption)?.value
          ? (data?.jobLevel as ISelectOption)?.value
          : undefined
      }

      return onFinishCallback(formatData)
    },
    [onFinishCallback]
  )

  useEffect(() => {
    if (formResetFuncRef?.current) {
      // formResetFuncRef.current(filter)
    }
  }, [filter])

  return (
    <DynamicImportForm
      id="career-search-form"
      className="mb-4 w-full tablet:mb-6"
      schema={schemaCareerSearch(t)}
      defaultValue={filter}
      onSubmit={onSubmit}>
      {({ formState, control, getValues, reset }) => {
        const data = getValues()
        formResetFuncRef.current = reset

        return (
          <>
            <div className="mb-3 tablet:mb-4">
              <Controller
                control={control}
                name="search"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem
                      destructiveText={formState.errors?.search?.message}>
                      <InputGroup>
                        <InputLeftAddon>
                          <IconWrapper name="Search" />
                        </InputLeftAddon>
                        <Input
                          onChange={(value) => {
                            onChange(value)

                            if (careerSearchTimeout)
                              clearTimeout(careerSearchTimeout)
                            careerSearchTimeout = setTimeout(() => {
                              // submit && submit()
                              onSubmit({
                                ...data,
                                search: value ? String(value) : ''
                              })
                            }, 500)
                          }}
                          value={value}
                          placeholder={`${t(
                            'careers:searchForm:searchPlaceholder'
                          )}`}
                          destructive={
                            formState.errors && !!formState.errors?.search
                          }
                          className="brand-color-input-focus pl-9"
                        />
                        {value && (
                          <InputRightAddon>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                onChange('')
                                onSubmit({ ...data, search: '' })
                                // submit && submit()
                              }}>
                              <IconWrapper name="X" />
                            </a>
                          </InputRightAddon>
                        )}
                      </InputGroup>
                    </FormControlItem>
                  )
                }}
              />
            </div>
            <div className="flex flex-wrap">
              <div className="mb-2 mr-2 tablet:mb-0">
                <Controller
                  control={control}
                  name="location"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlItem
                        destructiveText={formState.errors?.location?.message}>
                        <ComboboxSelect
                          options={promiseLocationsOptions}
                          buttonClassName="text-gray-900 max-w-[248px] brand-color-select-focus"
                          buttonFontWeightClassName="font-normal"
                          dropdownMenuClassName="!w-[248px]"
                          containerMenuClassName="max-w-[248px]"
                          size="lg"
                          menuOptionAlign="start"
                          menuOptionSide="bottom"
                          placeholder={`${t(
                            'careers:searchForm:locationPlaceholder'
                          )}`}
                          searchPlaceholder={`${t('label:placeholder:search')}`}
                          loadingMessage={`${t('label:loading')}`}
                          noOptionsMessage={`${t('label:noOptions')}`}
                          onChange={async (newValue) => {
                            onChange(newValue)
                            onSubmit({
                              ...data,
                              location: newValue as ISelectOption
                            })
                          }}
                          value={value}
                          destructive={
                            formState.errors && !!formState.errors?.location
                          }
                        />
                      </FormControlItem>
                    )
                  }}
                />
              </div>

              {!configHide?.department && (
                <div className="mb-2 mr-2 tablet:mb-0">
                  <Controller
                    control={control}
                    name="departmentIds"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControlItem
                          destructiveText={
                            formState.errors?.departmentIds?.message
                          }>
                          <ComboboxSelect
                            options={(params) => {
                              return careerPageSetting?.department_visibility ===
                                DEPARTMENT_SHOW_TOP_LEVEL
                                ? promisePublicDepartmentOptions({
                                    ...params,
                                    tenantSlug
                                  })
                                : promisePublicDepartmentMultiLevelOptions({
                                    ...params,
                                    tenantSlug
                                  })
                            }}
                            size="lg"
                            buttonClassName="text-gray-900 max-w-[248px] brand-color-select-focus"
                            checkboxSelectedClassName="brand-color-button rounded"
                            buttonFontWeightClassName="font-normal"
                            dropdownMenuClassName="!w-[248px]"
                            containerMenuClassName="max-w-[248px]"
                            menuOptionAlign="end"
                            menuOptionSide="bottom"
                            isMulti
                            onChange={(newValue) => {
                              onChange(newValue)
                              onSubmit({
                                ...data,
                                departmentIds: newValue as ISelectOption[]
                              })
                              // submit && submit()
                            }}
                            placeholder={`${t(
                              'careers:searchForm:departmentPlaceholder'
                            )}`}
                            searchPlaceholder={`${t(
                              'label:placeholder:search'
                            )}`}
                            loadingMessage={`${t('label:loading')}`}
                            noOptionsMessage={`${t('label:noOptions')}`}
                            value={value}
                            destructive={
                              formState.errors &&
                              !!formState.errors?.departmentIds
                            }
                          />
                        </FormControlItem>
                      )
                    }}
                  />
                </div>
              )}
              {!configHide?.remoteStatus ? (
                <div className="mb-2 mr-2 tablet:mb-0">
                  <Controller
                    control={control}
                    name="remoteStatusOption"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControlItem
                          destructiveText={
                            formState.errors?.remoteStatusOption?.message
                          }>
                          <ComboboxSelect
                            onChange={(newValue) => {
                              onChange(newValue)
                              onSubmit({
                                ...data,
                                remoteStatusOption: newValue as ISelectOption
                              })
                            }}
                            value={value}
                            size="lg"
                            menuOptionAlign="end"
                            menuOptionSide="bottom"
                            buttonClassName="text-gray-900 brand-color-select-focus"
                            dropdownMenuClassName="!w-[248px]"
                            containerMenuClassName="max-w-[248px]"
                            buttonFontWeightClassName="font-normal"
                            placeholder={`${t(
                              'careers:searchForm:remoteStatusPlaceholder'
                            )}`}
                            searchPlaceholder={`${t(
                              'label:placeholder:search'
                            )}`}
                            loadingMessage={`${t('label:loading')}`}
                            noOptionsMessage={`${t('label:noOptions')}`}
                            options={jobRemoteStatusOptions}
                            destructive={
                              formState.errors &&
                              !!formState.errors?.remoteStatusOption
                            }
                          />
                        </FormControlItem>
                      )
                    }}
                  />
                </div>
              ) : null}
              {!configHide?.jobLevel && (
                <div className="">
                  <Controller
                    control={control}
                    name="jobLevel"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControlItem
                          destructiveText={formState.errors?.jobLevel?.message}>
                          <ComboboxSelect
                            onChange={(newValue) => {
                              onChange(newValue)
                              onSubmit({
                                ...data,
                                jobLevel: newValue as ISelectOption
                              })
                            }}
                            value={value as ISelectOption}
                            size="lg"
                            menuOptionAlign="start"
                            menuOptionSide="bottom"
                            buttonClassName="text-gray-900 brand-color-select-focus"
                            dropdownMenuClassName="!w-[248px]"
                            containerMenuClassName="max-w-[248px]"
                            buttonFontWeightClassName="font-normal"
                            placeholder={`${t(
                              'careers:searchForm:jobLevelPlaceholder'
                            )}`}
                            searchPlaceholder={`${t(
                              'label:placeholder:search'
                            )}`}
                            loadingMessage={`${t('label:loading')}`}
                            noOptionsMessage={`${t('label:noOptions')}`}
                            options={jobLevelOptions}
                            destructive={
                              formState.errors && !!formState.errors?.jobLevel
                            }
                          />
                        </FormControlItem>
                      )
                    }}
                  />
                </div>
              )}
            </div>
          </>
        )
      }}
    </DynamicImportForm>
  )
}

export default CareerSearch

import { Trans, useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { FC, useCallback, useState } from 'react'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { Button } from '~/core/ui/Button'
import Empty from '~/core/ui/Empty'
import { TypographyText } from '~/core/ui/Text'
import QueryTenantCareers from '~/lib/features/careers/[id]/graphql/query-tenant-careers'
import {
  CareerPageSettingType,
  ICareer,
  ICareerForm,
  ICareerSearchParam,
  TenantType
} from '~/lib/features/careers/[id]/types'
import {
  IPageResult,
  useInfinityQuerySearch
} from '~/lib/hooks/use-infinity-query-search'
import CareerCard from './CareerCard'
import CareerSearch from './CareerSearch'
import CareersListInfinity from './CareersListInfinity'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import useBoundStore from '~/lib/store'
import { Skeleton } from '~/core/ui/Skeleton'
import { IRouterWithID, ITenantDetail } from '~/core/@types/global'
import { AGENCY_TENANT } from '~/core/constants/enum'
import usePublicSettingJobFields from '~/lib/features/careers/[id]/hooks/use-public-setting-job-fields'
import { SYSTEM_JOB_FIELDS } from '~/lib/features/jobs/utilities/enum'

export const CareerListPageSize = 10
export const CareerSimpleJobCountId = 'career-simple-job-opening-count'
const CareerList: FC<{
  className?: string
  tenantSlug?: IRouterWithID
  careerPageSetting?: CareerPageSettingType
  tenant?: TenantType | ITenantDetail
}> = ({ className = '', tenantSlug = '', careerPageSetting, tenant }) => {
  const [queryKey, setQueryKey] = useState<ICareerForm>({
    location: undefined,
    departmentIds: undefined,
    remoteStatusOption: undefined
  })
  const { t } = useTranslation()
  const { query } = useRouter()
  const { clientGraphQL } = useContextGraphQL()
  const setToast = useBoundStore((state) => state.setToast)
  const { isShowSystemFieldWithFilter, isShowSystemFieldWithCareerSite } =
    usePublicSettingJobFields()
  const fetchData = useCallback(
    async (
      pageParam = {} as ICareerSearchParam
    ): Promise<IPageResult<ICareer>> => {
      return clientGraphQL
        .query(QueryTenantCareers, {
          limit: CareerListPageSize,
          search: pageParam.search || '',
          tenantSlug: query.id || tenantSlug,
          page: pageParam?.page || 1,
          ...(pageParam.state
            ? {
                country: pageParam.country,
                state: pageParam.state
              }
            : {}),
          ...(pageParam.location?.id
            ? { countryStateId: Number(pageParam.location?.id) }
            : {}),
          ...(pageParam.departmentIds
            ? {
                departmentIds: pageParam.departmentIds?.map((item) =>
                  Number(item.value)
                )
              }
            : {}),
          ...(pageParam?.jobLevel
            ? {
                jobLevel: pageParam.jobLevel
              }
            : {}),
          ...(!Array.isArray(pageParam.remoteStatus) && pageParam.remoteStatus
            ? { remoteStatus: pageParam.remoteStatus }
            : {})
        })
        .toPromise()
        .then((result: IResponseContextResult<ICareerForm>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.careers.list,
              setToast
            })

            return {
              data: [],
              meta: {
                totalRowCount: 0,
                pageSize: CareerListPageSize
              }
            }
          }

          const { publicJobsList } = result.data
          const collection = publicJobsList?.collection || []
          const metadata = publicJobsList?.metadata || {}

          return {
            data: collection,
            meta: {
              totalRowCount: metadata.totalCount,
              pageSize: CareerListPageSize
            }
          }
        })
    },
    [query]
  )

  const {
    data,
    fetchNextPage,
    isFetching,
    isLoading,
    isFetchedAfterMount,
    hasNextPage,
    isFetchingNextPage
  } = useInfinityQuerySearch({
    configuration,
    fetchData,
    queryKey
  })

  const onSearch = useCallback(
    async (data: ICareerSearchParam) => {
      setQueryKey({ ...data, page: 1, key: (queryKey?.key || 1) + 1 })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryKey?.key]
  )

  return data?.pages?.[0]?.meta?.totalRowCount !== 0 ||
    (data?.pages?.[0]?.meta?.totalRowCount === 0 &&
      !!Object.values(queryKey).find((value) => !!value)) ? (
    <div className={className}>
      <CareerSearch
        configHide={{
          remoteStatus: !isShowSystemFieldWithFilter(
            SYSTEM_JOB_FIELDS['remote_status']
          ),
          department: tenant?.companyKind === AGENCY_TENANT,
          jobLevel: !isShowSystemFieldWithFilter(SYSTEM_JOB_FIELDS['job_level'])
        }}
        tenantSlug={tenantSlug}
        tenant={tenant}
        filter={queryKey}
        onFinishCallback={onSearch}
        careerPageSetting={careerPageSetting}
      />
      <div id={CareerSimpleJobCountId} className="mb-4 flex tablet:mb-6">
        {data?.pages?.[0]?.meta?.totalRowCount ? (
          <TypographyText className="text-base text-gray-700 dark:text-gray-300">
            <Trans
              i18nKey="careers:jobOpening"
              values={{ number: data?.pages?.[0]?.meta?.totalRowCount }}>
              <span className="font-medium" />
            </Trans>
          </TypographyText>
        ) : null}
      </div>
      {isFetching && !isFetchingNextPage ? (
        <>
          {[1, 2, 3, 4, 5, 6].map((index) => (
            <div
              key={index}
              className="border-b border-b-gray-100 pb-[23px] pt-6 first:pt-0">
              <Skeleton className="mb-2 h-[26px] w-full rounded" />
              <div className="flex justify-between">
                <Skeleton className="h-6 w-3/5 rounded" />
                <Skeleton className="h-6 w-1/5 rounded" />
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="">
          <CareersListInfinity
            search={{
              filter: queryKey
            }}
            emptyConfig={{
              classNameEmpty: 'mt-8 tablet:mt-[130px] text-center',
              titleSearch: `${t('careers:noResult:title')}`,
              descriptionSearch: `${t('careers:noResult:description')}`,
              buttonTitleSearch: `${t('careers:noResult:clearFilter')}`,
              buttonTitleSearchOnClick: () => onSearch({ search: '' }),
              buttonClassNameText: 'brand-color-text brand-color-ring'
            }}
            listConfig={{
              renderButton: (
                <Button
                  configurations="default"
                  label={`${t('common:infinity:showMore')}`}
                  size="md"
                  type="secondary"
                  className="brand-color-text brand-color-ring"
                  isDisabled={isFetchingNextPage}
                  isLoading={isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                />
              ),
              renderItem: (data) => (
                <CareerCard
                  key={data.id}
                  tenantSlug={tenantSlug}
                  data={data}
                  configHideInfo={{
                    salary: !isShowSystemFieldWithCareerSite(
                      SYSTEM_JOB_FIELDS.salary
                    ),
                    remoteStatus: !isShowSystemFieldWithCareerSite(
                      SYSTEM_JOB_FIELDS.remote_status
                    ),
                    employmentType: !isShowSystemFieldWithCareerSite(
                      SYSTEM_JOB_FIELDS.employment_type
                    )
                  }}
                />
              ),
              endOfList: `${t('common:table:endOfList')}`
            }}
            dataQuery={{
              hasNextPage,
              isFetching,
              isLoading,
              isFetchedAfterMount,
              data
            }}
          />
        </div>
      )}
    </div>
  ) : (
    <Empty
      isShowIcon={false}
      description={`${t('careers:emptyList:description')}`}
      title={`${t('careers:emptyList:title')}`}
    />
  )
}

export default CareerList
